import React from "react";
//--material-ui
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
//--components
import Table from "Components/Table/Table";
//--icons
import { HideResult } from "assets/Icons/Icons";
//--style
import style from "assets/jss/app/components/result";
//--helper
import ZoomSelect from "helper/GIS/ZoomSelect";
import HelperDraw from "helper/GIS/draw";

//--data
import constant from "data/constant";
import Store from "data/store";

const store = Store.getInstance();
const useStyles = makeStyles(style as any);

interface Props {
  columns: string[];
  data: any[];
  resultVisibilityHandler: Function;
  resultVisible: boolean;
}

export default function SpatialQuery({
  columns,
  data,
  resultVisible,
  resultVisibilityHandler,
}: Props) {
  const classes = useStyles();
  //***************************************helpers-start****************************************** */
  const rowMouseEnterHandler = (pBl_Code: string) => {
    ZoomSelect._DoQuery(
      `${constant.resources.fl_basemap}/5`,
      `Bl_Code = '${pBl_Code}'`,
      [],
      { Hover: true, ClearGraphics: true },
      store.MapView,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      store.MapView?.spatialReference
    ).then((evt) => {});
  };
  const rowMouseClickHandler = (pBl_Code: string) => {
    ZoomSelect._DoQuery(
      `${constant.resources.fl_basemap}/5`,
      `Bl_Code = '${pBl_Code}'`,
      [],
      { Select: true, Zoom: true, ClearGraphics: true },
      store.MapView,
      store.Gl_Result,
      undefined,
      undefined,
      11,
      undefined,
      store.MapView?.spatialReference
    ).then((evt) => {});
  };
  const rowMouseLeaveHandler = () => {
    HelperDraw.clearViewGraphics(store.MapView);
  };
  //***************************************helpers-start****************************************** */

  return (
    <Collapse in={resultVisible}>
      <Paper elevation={4} className={classes.root}>
        <div
          className={classes.hideSearch}
          onClick={() => {
            resultVisibilityHandler(false);
            HelperDraw.clearAllGraphics(store.MapView);
          }}
        >
          <Typography
            color={"textPrimary"}
            variant="subtitle1"
            align="left"
            display="inline"
            onClick={() => {}}
          >
            نتائج البحث
          </Typography>
          {<HideResult className={classes.hideSearchIcon} />}
        </div>
        <Table
          columns={columns}
          data={data}
          rowMouseEnterHandler={rowMouseEnterHandler}
          rowMouseClickHandler={rowMouseClickHandler}
          rowMouseLeaveHandler={rowMouseLeaveHandler}
        />
      </Paper>
    </Collapse>
  );
}
