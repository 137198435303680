import React from "react";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
// //--components
//--router
import { NavLink } from "react-router-dom";
//--assets
import { SignOutIcon } from "assets/Icons/Icons";
//--data
import routes from "routes";
//--styles
import style from "assets/jss/app/components/sidebar";
import { ActiveComponent } from "Enum/ActiveComponent";

const useStyles = makeStyles(style as any);

interface Props {
  handleDrawerToggle: Function;
  activeComponentHandler: Function;
  availableBillBoardsHandler: Function;
  signInVisibleHandler: Function;
  mobileOpen: boolean;
}

const Sidebar = ({
  handleDrawerToggle,
  activeComponentHandler,
  availableBillBoardsHandler,
  signInVisibleHandler,
  mobileOpen,
}: Props) => {
  const [activeState, setActiveState] = React.useState<any>({});
  const [openState, setOpenState] = React.useState<any>({});
  const classes = useStyles();
  const theme = useTheme();

  const drawer = (
    <div className={classes.drawer}>
      <div className={classes.toolbar} />
      <div className={classes.drawerLinks}>
        <Divider />
        <List>
          {routes.map((item, index) => (
            <React.Fragment>
              <ListItem
                button
                onClick={() => {
                  const tmpOpenState: any = { ...openState };
                  tmpOpenState[item.id] = !tmpOpenState[item.id];
                  setOpenState(tmpOpenState);
                }}
                key={item.id}
              >
                <ListItemIcon>{<item.icon />}</ListItemIcon>
                <ListItemText primary={item.text} />
                {openState[item.id] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openState[item.id]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.list?.map((listItem: { id: string; title: string }) => (
                    <NavLink
                      isActive={() => {
                        return activeState[listItem.id];
                      }}
                      activeClassName={classes.activeLink}
                      to={"#"}
                      className={""}
                      onClick={(e: any) => {
                        e.preventDefault();
                        const tmpActiveState: any = {};
                        tmpActiveState[listItem.id] = true;
                        setActiveState(tmpActiveState);
                        if (
                          listItem.id === ActiveComponent.AVAILABLE_BILLBOARDS
                        ) {
                          availableBillBoardsHandler();
                        }
                        activeComponentHandler(listItem.id as ActiveComponent);
                      }}
                    >
                      <ListItem button className={classes.nested}>
                        <ListItemText
                          primary={listItem.title}
                          classes={{ primary: classes.nestedText }}
                        />
                      </ListItem>
                    </NavLink>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
        <Divider />
      </div>
      <List>
        {[{ text: "تسجيل الخروج", icon: SignOutIcon }].map((item, index) => (
          <ListItem
            button
            key={item.text}
            onClick={() => {
              signInVisibleHandler(true);
            }}
          >
            <ListItemIcon>{<item.icon />}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <React.Fragment>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={() => {
            handleDrawerToggle();
          }}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
};
export default Sidebar;
