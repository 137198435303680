import React from "react";
//--components
import Typography from "@material-ui/core/Typography";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
//--Assetss
import OctoberLogo from "assets/img/6OctoberLogo.jpg";
//--styles
import styles from "assets/jss/app/components/login";

const useStyles = makeStyles(styles as any);
interface Iprops {
  signInClickHandler: Function;
}
const Login = ({ signInClickHandler }: Iprops) => {
  const classes = useStyles();
  return (
    <div className={classes.loginContianer}>
      <Paper className={classes.login}>
        <div className={classes.close}>اغلاق</div>
        <div className={classes.logoContianer}>
          <img className={classes.logo} src={OctoberLogo} alt="لوجو 6 اكتوبر" />
        </div>
        <Typography
          color={"secondary"}
          variant="h6"
          gutterBottom
          align="center"
        >
          تسجيل الدخول
        </Typography>

        <div className={classes.form}>
          <TextField
            id="standard-required"
            label="الاسم"
            fullWidth
            style={{ marginBottom: "1rem" }}
            value="demo"
          />
          <TextField
            id="standard-required"
            label="كلمة المرور"
            fullWidth
            type="password"
            value="test"
          />
        </div>
        <div className={classes.forgetPasswordContainer}>
          <a href="#" className={classes.forgetPassword}>
            هل نسيت كلمة المرور؟
          </a>
        </div>
        <div className={classes.btnSignIn}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              signInClickHandler();
            }}
          >
            تسجيل الدخول
          </Button>
        </div>
        <div>
          <span className={classes.noAccount}>ليس لديك حساب؟</span>
          <a href="#" className={classes.createNewAccount}>
            انشاء حساب جديد
          </a>
        </div>
      </Paper>
    </div>
  );
};

export default Login;
