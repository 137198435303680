import { Theme } from "@material-ui/core/styles";
import { drawerWidth } from "assets/jss/app";

const resultStyle = (theme: Theme) => ({
  root: {
    width: `calc(100vw - ${drawerWidth + 2 * theme.spacing(1)}px)`,
    height: "40vh",
    position: "fixed",
    right: theme.spacing(1),
    top: `calc(60vh - ${theme.spacing(1)}px)`,
    zIndex: "999999",
    background: "white",
    overflowY: "scroll",
    [theme.breakpoints.down("xs")]: {
      width: `calc(100vw - ${2 * theme.spacing(1)}px)`,
    },
  },
  hideSearch: {
    width: `136px`,
    height: "40px",
    position: "fixed",
    top: `calc(60vh - ${theme.spacing(1) + 40}px)`,
    zIndex: "999999",
    background: "white",
    right: theme.spacing(1),
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    cursor: "pointer",
  },
  hideSearchIcon: {
    width: "15px",
    height: "18px",
  },
});

export default resultStyle;
