import { Theme } from "@material-ui/core/styles";

const headerStyle = (theme: Theme) => ({
  appBar: {
    [theme.breakpoints.up("sm")]: {
      zIndex: 9999,
    },
  },
  octoberLogoButton: {
    marginRight: "auto",
  },
  octoberLogo: {
    width: "54px",
    height: "56px",
  },
  logoContainer: {
    // "@media (min-width: 768px)": {
    //   marginRight: "1rem",
    // },
  },
  logoButton: {},
  logo: {},
});

export default headerStyle;
