import React from "react";

//--components
import Login from "Components/Login/Login";
// material-ui components
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

//--styles
import styles from "assets/jss/app/pages/login";
//--assets
import loginMap from "assets/img/loginMap.svg";

const useStyles = makeStyles(styles as any);

interface Iprops {
  signInClickHandler: Function;
}

const LoginPage = ({ signInClickHandler }: Iprops) => {
  //************************************ HOOKS-START ***********************************************/
  const classes = useStyles();
  //************************************ HOOKS-END ***********************************************/

  return (
    <div className={classes.loginPageContianer}>
      <div className={classes.loginLogin}>
        <Login
          signInClickHandler={() => {
            signInClickHandler(false);
          }}
        />
      </div>
      <Hidden smDown>
        <div className={classes.loginMapContainer}>
          <img src={loginMap} className={classes.loginMap} alt="map"></img>
        </div>
      </Hidden>
    </div>
  );
};

export default LoginPage;
