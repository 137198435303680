export default class ArrayHelper {
  public static _Distinct_Object(pArray: any, pKey: string): any {
    const result = [];
    const map = new Map();
    for (const item of pArray) {
      if (!map.has(item[pKey])) {
        map.set(item[pKey], true); // set any value to Map
        result.push({ ...item });
      }
    }
    return result;
  }
  public static _Distinct(pArray: any): any {
    var unique = pArray.filter(ArrayHelper._OnlyUnique);
    return unique;
  }
  private static _OnlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }
  public static _Compare = (a: any, b: any) => {
    // Use toUpperCase() to ignore character casing

    if (typeof a.label == "string") {
      return ArrayHelper.compareStrings(a, b);
    } else {
      return ArrayHelper.compareNumbers(a, b);
    }
  };

  public static _Sort = (arr: any[]) => {
    // Use toUpperCase() to ignore character casing
    return arr.sort();
  };
  public static _GetSmallest = (arr: any[]) => {
    // Use toUpperCase() to ignore character casing
    return ArrayHelper._Sort(arr)[0];
  };
  public static _GetLargest = (arr: any[]) => {
    // Use toUpperCase() to ignore character casing
    return ArrayHelper._Sort(arr)[arr.length - 1];
  };
  private static compareStrings = function (a: any, b: any) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.label.toUpperCase();
    const bandB = b.label.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  };

  private static compareNumbers = function (a: any, b: any) {
    let comparison = 0;
    if (a > b) {
      comparison = 1;
    } else if (a < b) {
      comparison = -1;
    }
    return comparison;
  };
}
