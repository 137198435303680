import { Theme } from "@material-ui/core/styles";

const sidebarStyle = (theme: Theme) => ({
  root: {
    backgroundColor: "transparent",
  },
  content: {
    width: "35vw",
    maxWidth: "430px",
    height: "40vh",
    maxHeight: "301px",
    padding: "1rem",
  },
  dialogContent: {
    display: "flex",
    justifyContent: "center",
  },
  closeLink: {
    textDecoration: "underline",
    cursor: "pointer",
  },
});

export default sidebarStyle;
