import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
//--components
import Dialog from "Components/Dialog/Dialog";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
///--enum
import { ActiveComponent } from "Enum/ActiveComponent";
//--data
import Store from "data/store";
//--style
import style from "assets/jss/app/components/SpatialAnalysis";

const store = Store.getInstance();
const useStyles = makeStyles(style as any);

interface Props {
  open: boolean;
  dialogCloseHandler: Function;
}

export default function SpatialQuery({ open, dialogCloseHandler }: Props) {
  const [drawType, setDrawType] = useState<string>("pointDraw");
  const classes = useStyles();
  return (
    <Dialog
      title={"البحث المكاني"}
      actionTitle={"عرض"}
      open={open}
      dialogCloseHandler={dialogCloseHandler}
      dialogConfirmHandler={() => {
        dialogCloseHandler(ActiveComponent.NONE);
        if (drawType === "pointDraw") {
          store.sketchVM?.create("point", { mode: "click" });
        } else {
          store.sketchVM?.create("polygon", { mode: "freehand" });
        }
      }}
      contentStyle={{ display: "flex", justifyContent: "center" }}
    >
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup
          row
          aria-label="position"
          name="position"
          defaultValue="top"
          onChange={(event) => {
            setDrawType((event.target as HTMLInputElement).value);
          }}
        >
          <FormControlLabel
            value="pointDraw"
            control={
              <Radio color="primary" checked={drawType === "pointDraw"} />
            }
            label="نقطة علي الخريطة"
            classes={{ root: classes.radioRoot }}
          />
          <FormControlLabel
            value="freeDraw"
            control={
              <Radio color="primary" checked={drawType === "freeDraw"} />
            }
            label="رسم حر"
          />
        </RadioGroup>
      </FormControl>
    </Dialog>
  );
}
