import _Feature from "interface/GIS/Utils/feature";

export default class Store {
  private static instance: Store;
  MapView?: __esri.MapView;
  Gl_Result?: __esri.GraphicsLayer;
  Gl_Draw?: __esri.GraphicsLayer;
  Gl_SelectMany: __esri.GraphicsLayer | undefined;
  homeWidget?: __esri.Home;
  selectedFeatures?: _Feature[];
  legendColorPaletteHex: string[];
  legendColorPalette: number[][];
  sketchVM?: __esri.SketchViewModel;

  private constructor() {
    this.legendColorPaletteHex = [
      "#ff9999",
      "#ff6666",
      "#ff3333",
      "#b30000",
      "#4d0000",
    ];
    this.legendColorPalette = [
      [255, 153, 153, 0.75],
      [255, 102, 102, 0.75],
      [255, 51, 51, 0.75],
      [179, 0, 0, 0.75],
      [77, 0, 0, 0.75],
    ];
  }

  public static getInstance(): Store {
    if (!Store.instance) {
      Store.instance = new Store();
    }

    return Store.instance;
  }
}
