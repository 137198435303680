import { Theme } from "@material-ui/core/styles";
import { blackColor } from "assets/jss/app";

const companyContractStyle = (theme: Theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: "120px",
    justifyContent: "center",
    alignElements: "center",
    height: "100%",
  },
  radioRoot: {},
});

export default companyContractStyle;
