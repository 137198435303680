import React from "react";
//--material-ui
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
//--style
import style from "assets/jss/app/components/header";
//--assets
import octoberLogo from "assets/img/6OctoberLogo.svg";
import logo from "assets/img/logo.svg";

const useStyles = makeStyles(style as any);
const PrimarySearchAppBar = (props: any) => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar} color={"secondary"}>
      <Toolbar>
        <Button className={classes.octoberLogoButton}>
          <img src={octoberLogo} className={classes.octoberLogo}></img>
        </Button>
        <Hidden xsDown implementation="css">
          <div className={classes.logoContainer}>
            <Typography display="inline" variant="subtitle1" color="primary">
              <Box fontWeight="fontWeightBold" m={1} display="inline">
                تحت رعاية
              </Box>
            </Typography>
            <Button className={classes.logoButton}>
              <img src={logo} className={classes.logo}></img>
            </Button>
          </div>
        </Hidden>
        <Hidden smUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={props.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};
export default PrimarySearchAppBar;
