import { Theme } from "@material-ui/core/styles";
import { blackColor } from "assets/jss/app";

const companyContractStyle = (theme: Theme) => ({
  root: {
    // border: "dashed",
    marginTop: "17px",
    display: "flex",
  },
  typo: {
    flex: 1,
  },
  typoResult: {
    flex: 1,
    fontWeight: "bold",
  },
});

export default companyContractStyle;
