import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
//--components
import Dialog from "Components/Dialog/Dialog";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
//--helper
import ZoomSelect from "helper/GIS/ZoomSelect";
import HelperArray from "helper/array";
//--Enum
import { ActiveComponent } from "Enum/ActiveComponent";
//--style
import style from "assets/jss/app/components/CompanyContract";
import constant from "data/constant";
import _Feature from "interface/GIS/Utils/feature";
const useStyles = makeStyles(style as any);

interface Props {
  open: boolean;
  dialogCloseHandler: Function;
  searchHandler: Function;
}

export default function CompanyContract({
  open,
  dialogCloseHandler,
  searchHandler,
}: Props) {
  /*************************************hooks-start****************************************** */
  const classes = useStyles();
  const [companyOptions, setCompanyOptions] = useState<string[]>([]);
  const [company, setCompany] = useState<string>("");

  useEffect(() => {
    ZoomSelect._DoQuery(
      `${constant.resources.fl_basemap}/5`,
      "Co_Name is not null and Co_Name <> '<Null>'",
      ["Co_Name"],
      { Query: true }
    ).then((evt: _Feature[]) => {
      const companyNames: string[] = evt
        .map((x) => x.attributes?.Co_Name)
        .sort();
      setCompanyOptions(HelperArray._Distinct(companyNames));
    });
  }, []);
  /*************************************hooks-end****************************************** */

  /*************************************helper-start****************************************** */

  /*************************************hooks-end****************************************** */

  return (
    <Dialog
      title={"توزيع تعاقدات الشركات"}
      actionTitle={"عرض"}
      actionDisabled={company ? false : true}
      open={open}
      dialogCloseHandler={dialogCloseHandler}
      dialogConfirmHandler={() => {
        dialogCloseHandler(ActiveComponent.NONE);
        if (company) {
          searchHandler(`Co_Name = '${company}'`);
        }
      }}
    >
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel
          shrink
          id="company-lablel"
          classes={{ root: classes.inputLabel }}
        >
          إختر شركة من القائمة
        </InputLabel>
        <Select
          labelId="company-lablel"
          id="ddl-company-lablel"
          value={company}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setCompany(event.target.value as string);
          }}
          displayEmpty
          className={classes.selectEmpty}
        >
          <MenuItem value="">
            <em>إختر</em>
          </MenuItem>
          {companyOptions.map((x) => (
            <MenuItem value={x}>{x}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Dialog>
  );
}
