import React from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
interface Iprops {
  columns: string[];
  data: any[];
  rowMouseEnterHandler: Function;
  rowMouseClickHandler: Function;
  rowMouseLeaveHandler: Function;
}
export default function CustomizedTables({
  columns,
  data,
  rowMouseEnterHandler,
  rowMouseClickHandler,
  rowMouseLeaveHandler,
}: Iprops) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              return <StyledTableCell align="right">{column}</StyledTableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: any) => {
            let cells = [];
            for (var k in row) {
              if (row.hasOwnProperty(k) && k != "Bl_Code") {
                cells.push({ key: k, value: row[k] });
              }
            }
            return (
              <StyledTableRow
                key={row.Bl_Code}
                onMouseEnter={(evt) => {
                  rowMouseEnterHandler(row.Bl_Code);
                }}
                onMouseDown={(evt) => {
                  rowMouseClickHandler(row.Bl_Code);
                }}
                onMouseLeave={(evt) => {
                  rowMouseLeaveHandler();
                }}
              >
                {cells.map((cellValue) => (
                  <StyledTableCell align="right">
                    {cellValue.value}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            );
            // <StyledTableRow key={row.name}>
            //   <StyledTableCell component="th" scope="row">
            //     {row.name}
            //   </StyledTableCell>
            //   <StyledTableCell align="right">{row.calories}</StyledTableCell>
            //   <StyledTableCell align="right">{row.fat}</StyledTableCell>
            //   <StyledTableCell align="right">{row.carbs}</StyledTableCell>
            //   <StyledTableCell align="right">{row.protein}</StyledTableCell>
            // </StyledTableRow>;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
