import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

//--style
import style from "assets/jss/app/components/dialog";
import { ActiveComponent } from "Enum/ActiveComponent";
const useStyles = makeStyles(style as any);

interface Iprops {
  title: string;
  actionTitle: string;
  open: boolean;
  dialogCloseHandler: Function;
  dialogConfirmHandler: Function;
  children?: React.ReactNode;
  contentStyle?: any;
  actionDisabled?: boolean;
}
export default function FormDialog({
  title,
  actionTitle,
  open,
  dialogCloseHandler,
  dialogConfirmHandler,
  children,
  contentStyle,
  actionDisabled,
}: Iprops) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={() => {
        dialogCloseHandler(ActiveComponent.NONE);
      }}
      aria-labelledby="form-dialog-title"
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
      PaperProps={{
        classes: {
          root: classes.content,
        },
      }}
    >
      <Typography
        color={"textSecondary"}
        variant="caption"
        align="right"
        classes={{ root: classes.closeLink }}
        onClick={() => {
          dialogCloseHandler(ActiveComponent.NONE);
        }}
      >
        اغلاق
      </Typography>
      <Typography color={"primary"} variant="subtitle1" align="center">
        {title}
      </Typography>
      <DialogContent style={contentStyle}>{children}</DialogContent>
      {actionTitle ? (
        <DialogActions>
          <Button
            disabled={actionDisabled}
            onClick={() => {
              dialogConfirmHandler();
            }}
            color="primary"
            variant="contained"
          >
            {actionTitle}
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
}
