//arcgis-js-api
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";

export default class General {
  public static getGraphicsLayer = (
    pView: __esri.View,
    pLayerId: string
  ): GraphicsLayer => {
    return General.getLayer(pView, pLayerId) as GraphicsLayer;
  };
  public static getLayer = (
    pView: __esri.View,
    pLayerId: string
  ): __esri.Layer => {
    const layer: __esri.Layer = pView.map.findLayerById(pLayerId);
    return layer;
  };
}
