import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
// //--components
import Header from "Components/Header/Header";
import Sidebar from "Components/Sidebar/Sidebar";
import Map from "Components/Map/Map";
import Result from "Components/APP/Result/Result";
import CompanyContract from "Components/APP/CompanyContract/CompanyContract";
import ContractsInDept from "Components/APP/ContractsInDept/ContractsInDept";
import FinancialAnalysis from "Components/APP/FinancialAnalysis/FinancialAnalysis";
import SpatialQuery from "Components/APP/SpatialQuery/SpatialQuery";
//--helper
import ZoomSelect from "helper/GIS/ZoomSelect";
import HelperGeneral from "helper/general";

import { ActiveComponent } from "Enum/ActiveComponent";
import BillBoard from "interface/App/BillBoard";
import _Feature from "interface/GIS/Utils/feature";
//--data
import constant from "data/constant";
import Store from "data/store";

const store = Store.getInstance();
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: `100vh`, // `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
      // padding: theme.spacing(3),
    },
    mapContainer: {
      height: `calc(100vh - ${
        theme.mixins.toolbar.minHeight
          ? parseInt(theme.mixins.toolbar.minHeight.toString()) + 8
          : 0
      }px)`,
    },
  })
);

interface Props {
  signInVisibleHandler: Function;
}

export default function ResponsiveDrawer({ signInVisibleHandler }: Props) {
  //**********************************************HOOKS-START**************************************/

  const classes = useStyles();
  const [resultData, setResultData] = React.useState<BillBoard[]>([]);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [resultVisible, setResultVisible] = React.useState(false);
  const [activeComponent, setActiveComponent] =
    React.useState<ActiveComponent>();
  //**********************************************HOOKS-END**************************************/

  //**********************************************HELPER-START**************************************/

  const activeComponentHandler = (pActiveComponent: ActiveComponent) => {
    setActiveComponent(pActiveComponent);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const availableBillBoardsHandler = () => {
    searchHandler("Ctr_Code is null or Ctr_End_Date <= CURRENT_DATE ");
  };
  const searchHandler = (pQuery: string, pGeometry?: __esri.Geometry) => {
    ZoomSelect._DoQuery(
      `${constant.resources.fl_basemap}/5`,
      pQuery,
      [
        "Ctr_Code",
        "Co_Name",
        "Ctr_Start_Date",
        "Ctr_End_Date",
        "Bl_Description",
        "Bl_Street",
        "Bl_District",
        "Bl_Code",
        "Co_Money_Request",
      ],
      { Query: true, Select: true },
      store.MapView,
      store.Gl_SelectMany,
      undefined,
      pGeometry,
      undefined,
      undefined,
      undefined,
      constant.symbols.selectMany.point(9)
    ).then((evt: _Feature[]) => {
      console.log(evt);
      if (evt && evt.length > 0) {
        bindResultData(
          evt.map((x) => {
            if (x.attributes) {
              return {
                Ctr_Code: x.attributes.Ctr_Code
                  ? x.attributes.Ctr_Code != "<Null>"
                    ? x.attributes.Ctr_Code
                    : "-"
                  : "-",
                Co_Name:
                  !x.attributes.Co_Name || x.attributes.Co_Name === "<Null>"
                    ? "-"
                    : x.attributes.Co_Name,
                Ctr_Start_Date: x.attributes.Ctr_Start_Date
                  ? HelperGeneral.getDate(x.attributes.Ctr_Start_Date)
                  : "-",
                Ctr_End_Date: x.attributes.Ctr_End_Date
                  ? HelperGeneral.getDate(x.attributes.Ctr_End_Date)
                  : "-",
                Bl_Description: x.attributes.Bl_Description
                  ? x.attributes.Bl_Description
                  : "-",
                Bl_Street: x.attributes.Bl_Street
                  ? x.attributes.Bl_Street
                  : "-",
                Bl_District: x.attributes.Bl_District
                  ? x.attributes.Bl_District
                  : "-",
                Co_Money_Request: x.attributes.Co_Money_Request
                  ? x.attributes.Co_Money_Request.toFixed(2)
                  : "-",
                Bl_Code: x.attributes.Bl_Code,
              } as any;
            }
          })
        );
        if (store.MapView) {
          const extent: any = {
            spatialReference: {
              wkid: 102100,
            },
            xmax: 3462673.648539528,
            xmin: 3433856.888876055,
            ymax: 3512672.272134078,
            ymin: 3487524.489828289,
          };
          store.MapView.extent = extent;
        }
        resultVisibilityHandler(true);
      } else {
        bindResultData([]);
      }
    });
  };

  const bindResultData = (pData: BillBoard[]) => {
    setResultData(pData);
  };
  const resultVisibilityHandler = (pVisiblity: boolean) => {
    setResultVisible(pVisiblity);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header handleDrawerToggle={handleDrawerToggle} />
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Sidebar
          handleDrawerToggle={handleDrawerToggle}
          activeComponentHandler={activeComponentHandler}
          availableBillBoardsHandler={availableBillBoardsHandler}
          signInVisibleHandler={signInVisibleHandler}
          mobileOpen={mobileOpen}
        />
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.mapContainer}>
          <Map searchHandler={searchHandler} />
        </div>
      </main>
      <CompanyContract
        searchHandler={searchHandler}
        dialogCloseHandler={activeComponentHandler}
        open={activeComponent === ActiveComponent.COMPPANY_CONTRACT}
      />
      <ContractsInDept
        searchHandler={searchHandler}
        dialogCloseHandler={activeComponentHandler}
        open={activeComponent === ActiveComponent.CONTRACTS_IN_DEPT}
      />
      <FinancialAnalysis
        dialogCloseHandler={activeComponentHandler}
        open={activeComponent === ActiveComponent.FINANTIAL_ANALYSIS}
      />
      <SpatialQuery
        dialogCloseHandler={activeComponentHandler}
        open={activeComponent === ActiveComponent.SPATIAL_SEARCH}
      />
      <Result
        columns={[
          "رقم التعاقد",
          "إسم الشركة",
          "تاريخ بداية التعاقد",
          "تاريخ نهاية التعاقد",
          "نوع الاعلان",
          "الطريق",
          "الحي",
          "الدفعات المستحقة",
        ]}
        data={resultData}
        resultVisible={resultVisible}
        resultVisibilityHandler={resultVisibilityHandler}
      />
    </div>
  );
}
