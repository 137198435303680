const primaryColor = "#548438";
const blackColor = "#080d06";
const whiteColor = "#FFF";
const grayColor = "#707070";
const infoColor = "#EEF3EB";

// ##############################
// // // Function that converts from hex color to rgb color
// // // Example: input = #9c27b0 => output = 156, 39, 176
// // // Example: input = 9c27b0 => output = 156, 39, 176
// // // Example: input = #999 => output = 153, 153, 153
// // // Example: input = 999 => output = 153, 153, 153
// #############################
const hexToRgb = (input: any) => {
  input = input + "";
  input = input.replace("#", "");
  // regex doesn't allow the app to render on Safari-iphone
  // let hexRegex = /[0-9A-Fa-f]/g;
  // if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
  if (input.length !== 3 && input.length !== 6) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
};

// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const drawerWidth = 260;

export {
  hexToRgb,
  //variables
  drawerWidth,
  primaryColor,
  blackColor,
  whiteColor,
  grayColor,
  infoColor,
};
