import React from "react";
import ReactDOM from "react-dom";
// matrial-ui
import { ThemeProvider } from "@material-ui/core/styles";
// theme
import theme from "theme/theme";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import RTL from "theme/rtl";
import { Route, BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RTL>
        <Router>
          <Route path={"/"} component={App} />
        </Router>
      </RTL>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
