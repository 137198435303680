import React, { useState } from "react";

//--pages
import Home from "pages/Home";
import Login from "pages/Login";

const App = () => {
  //************************************ HOOKS-START ***********************************************/
  const [loginPageVisible, setLoginPageVisible] = useState<boolean>(true);

  //************************************ HOOKS-END ***********************************************/

  //************************************ HELPER-START ***********************************************/
  const signInVisibleHandler = (pVisible: boolean) => {
    setLoginPageVisible(pVisible);
  };
  //************************************ HELPER-END ***********************************************/

  return (
    <React.Fragment>
      {loginPageVisible ? (
        <Login signInClickHandler={signInVisibleHandler} />
      ) : (
        <Home signInVisibleHandler={signInVisibleHandler} />
      )}
    </React.Fragment>
  );
};

export default App;
