import { primaryColor } from "assets/jss/app";
const loginStyle = () => ({
  loginContianer: {
    flex: "0 1 85%",
    width: "65%",
  },
  login: {
    height: "100%",
    padding: "3% 5%",
    display: "flex",
    flexDirection: "column",
  },
  close: {
    textDecoration: "underline",
    textAlign: "right",
    color: "#707070",
    fontSize: "0.7rem",
    fontFamily: "DroidArabicKufiRegular",
  },
  logoContianer: {
    textAlign: "center",
  },
  logo: {
    width: "25%",
    minWidth: "108px",
    minHeight: "118px",
  },
  form: {
    marginBottom: "3rem",
  },
  forgetPasswordContainer: {
    textAlign: "right",
    paddingRight: "15%",
    marginBottom: "2.5rem",
  },
  forgetPassword: {
    textDecoration: "underline",
    color: primaryColor,
    fontFamily: "DroidArabicKufiRegular",
    fontSize: "0.6rem",
  },
  btnSignIn: {
    textAlign: "center",
    marginBottom: "1rem",
  },
  noAccount: {
    color: "#080D06",
    fontSize: "0.6rem",
    marginRight: "1rem",
    fontFamily: "DroidArabicKufiRegular",
  },
  createNewAccount: {
    color: primaryColor,
    fontSize: "0.6rem",
    fontFamily: "DroidArabicKufiRegular",
  },
});

export default loginStyle;
