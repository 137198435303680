/* eslint-disable import/no-anonymous-default-export */
const apiIP = "sapienterra.com";
const gisIP = "sapienterra.com";

export default {
  gisServerURL: `https://${gisIP}`,
  bookmarksLocalstorageName: "NUCA-Bookmarks",
  zoomLevel: 2,
  geometryServiceUrl:
    "https://sampleserver6.arcgisonline.com/arcgis/rest/services/Utilities/Geometry/GeometryServer",
  resources: {
    fl_basemap:
      "https://services.arcgis.com/E9zsdsZPiSq52EFG/arcgis/rest/services/Nuca/FeatureServer",
  },
  symbols: {
    select: {
      point: (pWidth: number) => {
        return {
          type: "simple-marker",
          style: "circle",
          color: [0, 194, 184, 0.75],
          size: `${pWidth}px`,
          outline: {
            color: "#027b75",
            width: 0.5,
            style: "",
            type: "simple-line",
          },
        };
      },
      polygon: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: [255, 255, 0, 0.05],
        style: "none",
        outline: {
          // autocasts as new SimpleLineSymbol()
          color: "#FF0101",
          width: 1.5,
          style: "dash-dot",
          type: "simple-line",
        },
      },
      polyline: {
        type: "simple-line", // autocasts as new SimpleLineSymbol()
        color: "#8A2BE2",
        width: 7.5,
        style: "dash",
      },
    },
    selectMany: {
      point: (pWidth: number) => {
        return {
          type: "simple-marker",
          style: "circle",
          color: [0, 255, 197, 0.75],
          size: `${pWidth}px`,
          outline: {
            color: [0, 0, 0, 0.25],
            width: 0.5,
            style: "",
            type: "simple-line",
          },
        };
      },
      polygon: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: [255, 255, 0, 0.05],
        style: "none",
        outline: {
          // autocasts as new SimpleLineSymbol()
          color: "#FF0101",
          width: 1.5,
          style: "dash-dot",
          type: "simple-line",
        },
      },
      polyline: {
        type: "simple-line", // autocasts as new SimpleLineSymbol()
        color: "#8A2BE2",
        width: 7.5,
        style: "dash",
      },
    },
    hover: {
      point: (pWidth: number) => {
        return {
          type: "simple-marker",
          style: "circle",
          color: [241, 103, 5, 0.75],
          size: `${pWidth}px`,
          outline: {
            color: "#FFF",
            width: 0.5,
            style: "solid",
            type: "simple-line",
          },
        };
      },
      polygon: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: [188, 166, 208, 0.75],
        style: "solid",
        outline: {
          // autocasts as new SimpleLineSymbol()
          color: "#8000f5",
          width: 2.5,
          style: "dash-dot",
          type: "simple-line",
        },
      },
      polyline: {
        type: "simple-line", // autocasts as new SimpleLineSymbol()
        color: "#8A2BE2",
        width: 7.5,
        style: "dash",
      },
    },
    draw: {
      point: (pWidth: number) => {
        return {
          type: "simple-marker",
          style: "circle",
          color: [219, 58, 52, 1],
          size: `${pWidth}px`,
          outline: {
            color: "#FFC857",
            width: 2.5,
            style: "",
            type: "simple-line",
          },
        };
      },
      polygon: (pColor: any) => {
        return {
          type: "simple-fill", // autocasts as new SimpleFillSymbol()
          color: pColor,
          style: "solid",
          outline: {
            // autocasts as new SimpleLineSymbol()
            color: "#FFF",
            width: 0.5,
            style: "none",
            type: "simple-line",
          },
        };
      },
      polyline: {
        type: "simple-line", // autocasts as new SimpleLineSymbol()
        color: "#8A2BE2",
        width: 7.5,
        style: "dash",
      },
    },
  },
};
