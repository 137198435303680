import React, { useEffect, useRef } from "react";
// nodejs library to set properties for components
// import PropTypes from "prop-types";
// arcgis
import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap";
import esriConfig from "@arcgis/core/config";
import { setLocale } from "@arcgis/core/intl";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
// import { addProxyRule } from "@arcgis/core/core/urlUtils";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";

//--widgets
import Bookmarks from "@arcgis/core/widgets/Bookmarks";
import AreaMeasurement2D from "@arcgis/core/widgets/AreaMeasurement2D";
import ScaleBar from "@arcgis/core/widgets/ScaleBar";
import DistanceMeasurement2D from "@arcgis/core/widgets/DistanceMeasurement2D";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import LayerList from "@arcgis/core/widgets/LayerList";
import Legend from "@arcgis/core/widgets/Legend";
import Expand from "@arcgis/core/widgets/Expand";
import Home from "@arcgis/core/widgets/Home";
import Compass from "@arcgis/core/widgets/Compass";
// import Print from "@arcgis/core/widgets/Print";

//--data
import Store from "data/store";
import constant from "data/constant";
//--helper
import HelperGeneral from "helper/general";
import HelperGeometry from "helper/GIS/geometry";
import HelperDraw from "helper/GIS/draw";
import Graphic from "@arcgis/core/Graphic";

interface Iprops {
  searchHandler: Function;
}
export default function AdminNavbar({ searchHandler }: Iprops) {
  const store = Store.getInstance();
  // Sets the locale to Arabic
  setLocale("ar");
  // Required: Set this property to insure assets resolve correctly.
  // esriConfig.assetsPath = "assets";
  // addProxyRule({
  //   urlPrefix: "http://localhost:6080/arcgis/rest/services",
  //   proxyUrl: `${constant.gisServerURL}/proxy/proxy.ashx`,
  // });

  // ref for main panel div
  const mapDiv: any = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (mapDiv && mapDiv.current) {
      initializeMap();
    }
  }, []);

  const initializeMap = () => {
    // Required: Set this property to insure assets resolve correctly.
    var map = new WebMap({
      basemap: "satellite",
    });

    const servicePopupTemplate = {
      // autocasts as new PopupTemplate()
      title: "المعالم الجغرافية",

      // Set content elements in the order to display.
      // The first element displayed here is the fieldInfos.
      content: [
        {
          type: "fields", // FieldsContentElement
          fieldInfos: [
            {
              fieldName: "Layer",
              visible: true,
              label: "الاستخدام",
            },
            {
              fieldName: "ملاحظات",
              visible: true,
              label: "ملاحظات",
            },
          ],
        },
      ],
    };
    let landuse = new FeatureLayer({
      url: `${constant.resources.fl_basemap}/8`,
      visible: true,
      title: "استخدامات الأراضي",
    });
    let services = new FeatureLayer({
      url: `${constant.resources.fl_basemap}/1`,
      visible: true,
      title: "المعالم الجغرافية",
      popupTemplate: servicePopupTemplate,
    });

    // Create the AttachmentsContent popup element
    // If the selected feature has attributes associated with it, they will display within the popup
    // let attachmentsElement = new AttachmentsContent({
    //   displayType: "preview",
    // });

    // // Create the PopupTemplate
    // let template = new PopupTemplate({
    //   title: "Beverly Hills trees by block",
    //   outFields: ["*"],
    //   content: [attachmentsElement],
    // });
    const template = {
      // autocasts as new PopupTemplate()
      title: "اللوحات الاعلانية",

      // Set content elements in the order to display.
      // The first element displayed here is the fieldInfos.
      content: [
        {
          type: "fields", // FieldsContentElement
          fieldInfos: [
            {
              fieldName: "Co_Name",
              visible: true,
              label: "اسم الشركة",
            },
            {
              fieldName: "Co_Level",
              visible: true,
              label: "تصنيف الشركة",
            },
            {
              fieldName: "Bl_Description",
              visible: true,
              label: "نوع الاعلان",
            },
            {
              fieldName: "Bl_Total_Price",
              visible: true,
              label: "اجمالي قيمة الاعلان",
              format: {
                digitSeparator: true,
                places: 2,
              },
            },
            {
              fieldName: "Co_Money_Request",
              visible: true,
              label: "الدفعات المستحقة",
              format: {
                digitSeparator: true,
                places: 2,
              },
            },
            {
              fieldName: "Ctr_End_Date",
              visible: true,
              label: "تاريخ نهاية التعاقد",
            },
          ],
        },
        {
          type: "attachments",
        },
      ],
    };
    const layer1 = new FeatureLayer({
      // URL to the service
      url: `${constant.resources.fl_basemap}/5`,
      popupTemplate: template,
      title: "اللوحات الاعلانية",
    });
    store.Gl_Draw = new GraphicsLayer({ listMode: "hide" });
    store.Gl_SelectMany = new GraphicsLayer({ listMode: "hide" });
    store.Gl_Result = new GraphicsLayer({ listMode: "hide" });
    map.addMany([
      landuse,
      layer1,
      services,
      store.Gl_SelectMany,
      store.Gl_Result,
      store.Gl_Draw,
    ]); // adds the layer to the map

    store.MapView = new MapView({
      map,
      container: mapDiv.current,
      extent: {
        spatialReference: {
          wkid: 102100,
        },
        xmax: 3458087.426842423, //right
        xmin: 3439322.1363984374, //left
        ymax: 3510149.8502006712, //top
        ymin: 3497575.959047777, //bottom
      },
      // center: [30.93474045126721, 29.923436157904305], // [39.835374946683274, 30.103967199349277 ],
      // zoom: 11,
      constraints: { minScale: 30000000 },
    });
    store.MapView.watch("extent", (evt) => {
      console.log(evt);
    });
    store.MapView.when(() => {
      /////////////////////////////////sketchviewModel
      // Create a new SketchViewModel and set
      // its required parameters
      store.sketchVM = new SketchViewModel({
        layer: store.Gl_Draw,
        view: store.MapView,
      });

      store.sketchVM.on("create", (event) => {
        if (event.state === "complete") {
          let geometry: any = event.graphic.geometry;
          // store.searchGeometry = event.graphic.geometry;
          if (geometry.type === "point") {
            geometry = HelperGeometry.doBuffer(geometry, {
              unit: "kilometers",
              width: 1,
            });
            if (store.MapView) {
              store.Gl_Draw?.remove(event.graphic);
              HelperDraw._Draw(
                store.MapView,
                [
                  new Graphic({
                    geometry: geometry,
                    symbol: constant.symbols.draw.polygon([
                      84, 132, 56, 0.5,
                    ]) as any,
                  }),
                ],
                store.Gl_Draw
              );
              store.Gl_Draw?.add(event.graphic);
            }
          }
          searchHandler("1=1", geometry);
        }
      });
      //////////////////////widgets
      store.homeWidget = new Home({
        view: store.MapView,
      });
      var compass = new Compass({
        view: store.MapView,
      });
      var scaleBar = new ScaleBar({
        view: store.MapView,
        unit: "dual",
      });

      let existingBookmarks = [];
      const existingBookmarksJson = localStorage.getItem(
        constant.bookmarksLocalstorageName
      );
      if (existingBookmarksJson) {
        existingBookmarks = JSON.parse(existingBookmarksJson);
      }
      const bookmarks = new Bookmarks({
        view: store.MapView,
        // allows bookmarks to be added, edited, or deleted
        editingEnabled: true,
        bookmarks: existingBookmarks,
      });
      const bookmarksExpand = new Expand({
        view: store.MapView,
        content: bookmarks,
        expandTooltip: "فتح الاشارات المرجعية",
        collapseTooltip: "اغلاق الاشارات المرجعية",
        group: "bottom-left",
      });
      bookmarks.bookmarks.on("change", (evt: any) => {
        bookmarkUpdateEditHandler(evt.target.items);
      });
      bookmarks.on("bookmark-edit", (evt: any) => {
        bookmarkEditHandler(evt);
      });
      // To add the AreaMeasurement2D widget to your map
      var measurementWidget = new AreaMeasurement2D({
        view: store.MapView,
      });
      const measurementWidgetExpand = new Expand({
        view: store.MapView,
        content: measurementWidget,
        expandTooltip: "فتح اداة قياس المساحة",
        collapseTooltip: "اغلاق اداة قياس المساحة",
        group: "bottom-left",
      });
      measurementWidgetExpand.watch("expanded", (expanded: boolean) => {
        if (!expanded) {
          measurementWidget.viewModel.clear();
        }
      });
      // To add the DistanceMeasurement2D widget to the map
      var distanceMeasurementWidget = new DistanceMeasurement2D({
        view: store.MapView,
      });
      const distanceMeasurementWidgetExpand = new Expand({
        view: store.MapView,
        content: distanceMeasurementWidget,
        expandTooltip: "فتح اداة قياس الطول",
        collapseTooltip: "اغلاق اداة قياس الطول",
        group: "bottom-left",
      });
      distanceMeasurementWidgetExpand.watch("expanded", (expanded: boolean) => {
        if (!expanded) {
          distanceMeasurementWidget.viewModel.clear();
        }
      });
      var basemapGallery = new BasemapGallery({
        view: store.MapView,
      });
      const basemapGalleryExpand = new Expand({
        view: store.MapView,
        content: basemapGallery,
        expandTooltip: "",
        collapseTooltip: "",
        group: "bottom-left",
      });
      var layerList = new LayerList({
        view: store.MapView,
      });
      const layerListExpand = new Expand({
        view: store.MapView,
        content: layerList,
        expandTooltip: "فتح طبقات الخريطة",
        collapseTooltip: "اغلاق طبقات الخريطة",
        group: "bottom-left",
      });
      var legend = new Legend({
        view: store.MapView,
      });
      const legendExpand = new Expand({
        view: store.MapView,
        content: legend,
        expandTooltip: "فتح مفتاح الخريطة",
        collapseTooltip: "اغلاق مفتاح الخريطة",
        group: "bottom-left",
      });

      // const print = new Print({
      //   view: store.MapView,
      //   // specify your own print service
      //   printServiceUrl:
      //     "http://localhost:6080/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task",
      // });

      // const printExpand = new Expand({
      //   view: store.MapView,
      //   content: print,
      //   expandTooltip: "فتح الطباعة",
      //   collapseTooltip: "اغلاق الطباعة",
      //   group: "bottom-left",
      // });

      // Add the widget to the top-left the view
      store.MapView?.ui.add(store.homeWidget, "top-left");
      store.MapView?.ui.add(compass, "top-left");
      // store.MapView?.ui.add(printExpand, "bottom-left");
      //--bottom-right
      store.MapView?.ui.add(scaleBar, "bottom-right");
      //--bottom-left
      store.MapView?.ui.add(bookmarksExpand, "bottom-left");
      store.MapView?.ui.add(distanceMeasurementWidgetExpand, "bottom-left");
      store.MapView?.ui.add(measurementWidgetExpand, "bottom-left");
      store.MapView?.ui.add(basemapGalleryExpand, "bottom-left");
      store.MapView?.ui.add(layerListExpand, "bottom-left");
      store.MapView?.ui.add(legendExpand, "bottom-left");
    });
  };
  const bookmarkUpdateEditHandler = (pBookmarks: any) => {
    const mappedBookmarks = pBookmarks.map((x: any) => {
      return {
        id: x.id ? x.id : HelperGeneral.createUUID(),
        extent: x.extent,
        name: x.name,
        thumbnail: x.thumbnail,
        viewpoint: x.viewpoint,
      };
    });
    localStorage.setItem(
      constant.bookmarksLocalstorageName,
      JSON.stringify(mappedBookmarks)
    );
  };
  const bookmarkEditHandler = (event: any) => {
    const rawBookmark = {
      extent: event.bookmark.extent,
      name: event.bookmark.name,
      thumbnail: event.bookmark.thumbnail,
      id: event.bookmark.id,
    };

    let existingBookmarks = [];
    const existingBookmarksJson = localStorage.getItem(
      constant.bookmarksLocalstorageName
    );
    if (existingBookmarksJson) {
      existingBookmarks = JSON.parse(existingBookmarksJson);
    }
    if (event.bookmark.id) {
      existingBookmarks = existingBookmarks.filter(
        (x: any) => x.id != rawBookmark.id
      );
    } else {
      rawBookmark.id = HelperGeneral.createUUID();
    }
    existingBookmarks.push(rawBookmark);
    localStorage.setItem(
      constant.bookmarksLocalstorageName,
      JSON.stringify(existingBookmarks)
    );
  };
  return <div id="viewDiv" style={{ height: "100%" }} ref={mapDiv}></div>;
}
