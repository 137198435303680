//--assets
import {
  MainDataIcon,
  ProcedureIcon,
  ReportPrintIcon,
  AnalysisIcon,
  SettingIcon,
  DataEditIcon,
} from "assets/Icons/Icons";

const routes = [
  {
    id: "mainData",
    text: "بيانات اساسية",
    icon: MainDataIcon,
    open: false,
    list: [
      { id: "1.1", title: "- بيانات الشركات" },
      { id: "1.2", title: "- بيانات الاعلانات" },
      { id: "1.3", title: "- بيانات التعاقدات" },
      { id: "1.4", title: "- السجلات السابقة" },
    ],
  },
  {
    id: "DataEntryEdit",
    text: "إدخال و تعديل البيانات",
    icon: DataEditIcon,
    open: false,
    list: [
      { id: "2.1", title: "- بيانات الشركات" },
      { id: "2.2", title: "- بيانات الاعلانات" },
      { id: "2.3", title: "- بيانات التعاقدات" },
    ],
  },
  {
    id: "procedure",
    text: "إجراءات و قرارات",
    icon: ProcedureIcon,
    open: false,
    list: [
      { id: "3.1", title: "- سداد قيمة التعاقد" },
      { id: "3.2", title: "- مخالفات الشركات" },
      { id: "3.3", title: "- إنذار تأخير السداد" },
      { id: "3.4", title: "- تجديد تعاقدات الشركات" },
      { id: "3.5", title: "- إنهاء تعاقد" },
    ],
  },
  {
    id: "report",
    text: "تقارير و طباعة",
    icon: ReportPrintIcon,
    open: false,
    list: [
      { id: "4.1", title: "- تقارير شركات" },
      { id: "4.2", title: "- تقارير إعلانات" },
      { id: "4.3", title: "- تقارير تعاقدات" },
      { id: "4.4", title: "- تقارير مالية" },
      { id: "4.5", title: "- إنشاء وطباعة عقد جديد" },
    ],
  },
  {
    id: "analysis",
    text: "التحليلات",
    icon: AnalysisIcon,
    open: false,
    list: [
      { id: "COMPPANY_CONTRACT", title: "- توزيع تعاقدات شركة" },
      { id: "CONTRACTS_IN_DEPT", title: "- تعاقدات عليها مديونات" },
      { id: "AVAILABLE_BILLBOARDS", title: "- إعلانات متاحة للتعاقد" },
      { id: "SPATIAL_SEARCH", title: "-  البحث المكاني" },
      { id: "FINANTIAL_ANALYSIS", title: "- التحليل المالي" },
    ],
  },
  {
    id: "setting",
    text: "إعدادات النظام",
    icon: SettingIcon,
    open: false,
    list: [
      { id: "5.1", title: "- إستخراج نسخة احتياطية" },
      { id: "5.2", title: "- إضافة أو تعديل علي بيانات المستخدم" },
      { id: "5.3", title: "- تجديد صلاحيات المستخدمين" },
      { id: "5.4", title: "- متابعة المستخدمين" },
    ],
  },
];
export default routes;
