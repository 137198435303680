import {} from "assets/jss/app";
import loginBackground from "assets/img/loginBackground.svg";
import loginMap from "assets/img/loginMap.svg";
const loginStyle = () => ({
  loginPageContianer: {
    width: "100vw",
    height: "98vh",
    display: "flex",
  },
  loginLogin: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 1,
    backgroundImage: `url("${loginBackground}")`,
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "-25%",
    backgroundPositionX: "70%",
  },
  loginMapContainer: {
    flex: 1,
  },
  loginMap: {
    width: "100%",
    height: "100%",
  },
});

export default loginStyle;
