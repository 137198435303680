class HelperGeneral {
  public static createUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  public static getDate(pDate: any) {
    const date = new Date(pDate);
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
  }
  public static numberWithCommas(x: any) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
}

export default HelperGeneral;
