import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
//--components
import Dialog from "Components/Dialog/Dialog";
import Typography from "@material-ui/core/Typography";
//--helper
import ZoomSelect from "helper/GIS/ZoomSelect";
import HelperGeneral from "helper/general";
//--Enum
import { ActiveComponent } from "Enum/ActiveComponent";
//--style
import style from "assets/jss/app/components/FinancialAnalysis";
//--data
import constant from "data/constant";
//--interface
import _Feature from "interface/GIS/Utils/feature";
const useStyles = makeStyles(style as any);

interface Props {
  open: boolean;
  dialogCloseHandler: Function;
}

export default function CompanyContract({ open, dialogCloseHandler }: Props) {
  /*************************************hooks-start****************************************** */
  const classes = useStyles();
  const [totalIncome, setTotalIncome] = useState<number>();
  const [totalDept, setTotalDept] = useState<number>();

  useEffect(() => {
    ZoomSelect._DoQuery(
      `${constant.resources.fl_basemap}/5`,
      "1=1",
      ["Bl_Total_Price", "Co_Money_Request"],
      {
        Query: true,
      }
    ).then((evt: _Feature[]) => {
      let totalprice = 0;
      let totalMoneyRequest = 0;

      evt.forEach((feature: _Feature) => {
        if (feature.attributes?.Bl_Total_Price) {
          totalprice += feature.attributes?.Bl_Total_Price;
        }
        if (feature.attributes?.Co_Money_Request) {
          totalMoneyRequest += feature.attributes?.Co_Money_Request;
        }
      });
      setTotalIncome(totalprice);
      setTotalDept(totalMoneyRequest);
    });
  }, []);
  /*************************************hooks-end****************************************** */

  /*************************************helper-start****************************************** */

  /*************************************hooks-end****************************************** */

  return (
    <Dialog
      title={"التحليل المالي"}
      actionTitle={""}
      open={open}
      dialogCloseHandler={dialogCloseHandler}
      dialogConfirmHandler={() => {
        dialogCloseHandler(ActiveComponent.NONE);
      }}
    >
      <div className={classes.root}>
        <Typography
          color={"textPrimary"}
          variant="caption"
          align="center"
          classes={{ root: classes.typo }}
        >
          إجمالي الدخل
        </Typography>
        <Typography
          color={"textPrimary"}
          variant="caption"
          align="center"
          classes={{ root: classes.typo }}
        >
          إجمالي المستحقات
        </Typography>
      </div>
      <div className={classes.root}>
        <Typography
          color={"primary"}
          variant="h5"
          align="center"
          classes={{ root: classes.typoResult }}
        >
          {totalIncome
            ? HelperGeneral.numberWithCommas(totalIncome.toFixed(2))
            : null}
        </Typography>
        <Typography
          color={"primary"}
          variant="h5"
          align="center"
          classes={{ root: classes.typoResult }}
        >
          {totalDept
            ? HelperGeneral.numberWithCommas(totalDept.toFixed(2))
            : null}
        </Typography>
      </div>
    </Dialog>
  );
}
