import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
//--components
import Dialog from "Components/Dialog/Dialog";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
//--helper
import ZoomSelect from "helper/GIS/ZoomSelect";
import HelperArray from "helper/array";
//--Enum
import { ActiveComponent } from "Enum/ActiveComponent";
//--style
import style from "assets/jss/app/components/CompanyContract";
import constant from "data/constant";
import _Feature from "interface/GIS/Utils/feature";
const useStyles = makeStyles(style as any);

interface Props {
  open: boolean;
  dialogCloseHandler: Function;
  searchHandler: Function;
}
const deptAmount: number[] = [0, 5000, 10000, 50000, 100000];
export default function CompanyContract({
  open,
  dialogCloseHandler,
  searchHandler,
}: Props) {
  /*************************************hooks-start****************************************** */
  const classes = useStyles();
  const [amount, setAmount] = useState<number>();

  /*************************************hooks-end****************************************** */

  /*************************************helper-start****************************************** */

  /*************************************hooks-end****************************************** */

  return (
    <Dialog
      title={"تعاقدات عليها مديونات"}
      actionTitle={"عرض"}
      actionDisabled={amount || amount == 0 ? false : true}
      open={open}
      dialogCloseHandler={dialogCloseHandler}
      dialogConfirmHandler={() => {
        dialogCloseHandler(ActiveComponent.NONE);
        if (amount || amount === 0) {
          searchHandler(`Co_Money_Request >= ${amount}`);
        }
      }}
    >
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel
          shrink
          id="company-lablel"
          classes={{ root: classes.inputLabel }}
        >
          إختر مبلغ من القائمة
        </InputLabel>
        <Select
          labelId="company-lablel"
          id="ddl-company-lablel"
          value={amount || amount === 0 ? amount : ""}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setAmount(event.target.value as number);
          }}
          displayEmpty
          className={classes.selectEmpty}
        >
          <MenuItem value="">
            <em>إختر</em>
          </MenuItem>
          {deptAmount.map((x) => (
            <MenuItem value={x}>أكبر من {x} </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Dialog>
  );
}
