import GeometrySetting from "interface/GIS/Utils/geometry";
import BufferSetting from "interface/GIS/Utils/buffer";
import { _Point, _Polyline, _Polygon } from "interface/GIS/Utils/common";
//arcgis-js-api
import Graphic from "@arcgis/core/Graphic";
import Point from "@arcgis/core/geometry/Point";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";

export default class NSGeometry {
  public static getPoint = (pGeometry: _Point, pSettings?: GeometrySetting) => {
    let point: any = new Point({
      longitude: pGeometry.longitude,
      latitude: pGeometry.latitude,
    });

    if (pSettings && pSettings.toGeographic) {
      point = NSGeometry.toGeographic(point);
    } else if (pSettings && pSettings.toWebMercator) {
      point = NSGeometry.toWebMercator(point);
    }
    if (pSettings && pSettings.buffer) {
      point = NSGeometry.doBuffer(point, pSettings.buffer);
    }
    return point;
  };

  public static getPolyline = (
    pGeometry: _Polyline,
    pSettings: GeometrySetting
  ) => {
    const polyline = {
      type: "polyline", // autocasts as new Polyline()
      paths: pGeometry.paths.map((point: _Point) => [
        point.longitude,
        point.latitude,
      ]),
    };

    if (pSettings && pSettings.buffer) {
      return NSGeometry.doBuffer(polyline, pSettings.buffer);
    } else {
      return polyline;
    }
  };

  public static getPolygon = (
    pGeometry: _Polygon,
    pSettings?: GeometrySetting
  ) => {
    const polygon = {
      type: "polygon",
      rings: pGeometry.rings.map((point: _Point) => [
        point.longitude,
        point.latitude,
      ]),
    };

    if (pSettings && pSettings.buffer) {
      return NSGeometry.doBuffer(polygon, pSettings.buffer);
    } else {
      return polygon;
    }
  };
  public static toGeographic = (pGeometry: __esri.Geometry) => {
    const gGeographic = webMercatorUtils.webMercatorToGeographic(pGeometry);
    return gGeographic;
  };

  public static toWebMercator = (pGeometry: __esri.Geometry) => {
    const gWebmercator = webMercatorUtils.geographicToWebMercator(pGeometry);
    return gWebmercator;
  };
  public static doBuffer = (pGeometry: any, pBufferSetting: BufferSetting) => {
    const graphic = new Graphic({
      geometry: pGeometry,
    });
    const bufferdGeometry = geometryEngine.geodesicBuffer(
      graphic.geometry,
      pBufferSetting.width,
      pBufferSetting.unit
    );
    return bufferdGeometry;
  };
  public static doMerge = (pGeometries: __esri.Geometry[]) => {
    const unionedGeometry = geometryEngine.union(pGeometries);
    return unionedGeometry;
  };
  public static doCut = (
    pGeometry: __esri.Geometry,
    pLine: __esri.Polyline
  ) => {
    const cutGeometry: __esri.Geometry[] = geometryEngine.cut(pGeometry, pLine);
    return cutGeometry;
  };
  public static doDifference = (
    pInputGeometry: __esri.Geometry,
    pSubtractor: __esri.Geometry
  ) => {
    const subtractedGeometry: __esri.Geometry | __esri.Geometry[] =
      geometryEngine.difference(pInputGeometry, pSubtractor);
    return subtractedGeometry;
  };

  static doIntersect(
    pGeometry: __esri.Geometry,
    pIntersector: __esri.Geometry
  ) {
    const intersectedGeometry: __esri.Geometry | __esri.Geometry[] =
      geometryEngine.intersect(pGeometry, pIntersector);
    return intersectedGeometry;
  }

  static _GetDistance(
    pGeometry: __esri.Geometry,
    pIntersector: __esri.Geometry,
    pUnit: number
  ) {
    const distance: number = geometryEngine.distance(
      pGeometry,
      pIntersector,
      pUnit
    );
    return distance;
  }

  static _FindNearst(
    pGeometry: __esri.Geometry,
    pArrGeometry: __esri.Geometry[]
  ) {
    let nearestGeoemtry: __esri.Geometry = pArrGeometry[0];
    let distance = NSGeometry._GetDistance(pGeometry, pArrGeometry[0], 1);
    pArrGeometry.forEach((geometry) => {
      const tmpDistance = NSGeometry._GetDistance(pGeometry, geometry, 1);
      if (tmpDistance < distance) distance = tmpDistance;
      nearestGeoemtry = geometry;
    });
    return nearestGeoemtry;
  }
}
