//--helpers
import Draw from "./draw";
import HelperGeometry from "helper/GIS/geometry";
//--data
import Constant from "data/constant";
//--interfaces
import _Feature from "interface/GIS/Utils/feature";
import ZoomSelectSetting from "interface/GIS/Utils/ZoomSelect";
//arcgis-js-api
import * as query from "@arcgis/core/rest/query";
import Query from "@arcgis/core/rest/support/Query";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import { project } from "@arcgis/core/rest/geometryService";
import ProjectParameters from "@arcgis/core/rest/support/ProjectParameters";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";

export default class ZoomSelect {
  private static Settings: ZoomSelectSetting;
  private static ExpandValue: number;

  public static _DoQuery = (
    pEndPointURL: string,
    pWhere: string,
    pOutFields: string[],
    pSettings: ZoomSelectSetting,
    pView?: __esri.MapView,
    pGraphicLayer?: GraphicsLayer,
    pOrderByFields?: string[],
    pSpatialQueryGeomtry?: any,
    pExpandValue?: number,
    pselectDistinct?: boolean,
    pOutSpatilRef?: __esri.SpatialReference,
    pSymbol?: any
  ) => {
    return new Promise<_Feature[]>((resolve: any, reject: any) => {
      ZoomSelect.Settings = pSettings;
      ZoomSelect.ExpandValue = pExpandValue ? pExpandValue : 1.5;

      
      let queryObject = new Query();
      queryObject.outFields = pOutFields;
      if (
        pSettings.ReturnGeometry ||
        pSettings.Select ||
        pSettings.Hover ||
        pSettings.Zoom
      ) {
        queryObject.returnGeometry = true;
      } else {
        queryObject.returnGeometry = false;
      }
      if (pselectDistinct) {
        queryObject.returnDistinctValues = true;
      }
      if (pOutSpatilRef) {
        queryObject.outSpatialReference = pOutSpatilRef;
      }
      if (pSpatialQueryGeomtry) {
        queryObject.geometry = pSpatialQueryGeomtry;
        queryObject.spatialRelationship = "intersects";
      }
      if (pOrderByFields) {
        queryObject.orderByFields = pOrderByFields;
      }
      queryObject.where = pWhere;
      query
        .executeQueryJSON(pEndPointURL,queryObject)
        .then((evt) => {
          ZoomSelect._QueryResultDone(
            resolve,
            reject,
            evt,
            pView,
            pGraphicLayer,
            pSymbol
          );
        })
        .catch(ZoomSelect.queryTaskErrorHandler);
    });
  };

  public static _QueryResultDone = (
    resolve: any,
    reject: any,
    evt: any,
    pView?: __esri.MapView,
    pGraphicLayer?: GraphicsLayer,
    pSymbol?: any
  ) => {
    let symbol: any = pSymbol;
    if (ZoomSelect.Settings.ClearGraphics) {
      if (pGraphicLayer) {
        Draw.clearGraphics(pGraphicLayer);
      } else {
        Draw.clearViewGraphics(pView);
      }
    }

    let Result: _Feature[] = [];
    if (evt.features.length > 0) {
      if (
        ZoomSelect.Settings.Hover ||
        (ZoomSelect.Settings.Select && !symbol)
      ) {
        if (ZoomSelect.Settings.Hover) {
          switch (evt.features[0].geometry.type) {
            case "point":
              symbol = Constant.symbols.hover.point(16);
              break;
            case "polyline":
              symbol = Constant.symbols.hover.polyline;
              break;
            case "polygon":
              symbol = Constant.symbols.hover.polygon;
              break;
            default:
          }
        } else {
          switch (evt.features[0].geometry.type) {
            case "point":
              symbol = Constant.symbols.select.point(16);
              break;
            case "polyline":
              symbol = Constant.symbols.select.polyline;
              break;
            case "polygon":
              symbol = Constant.symbols.select.polygon;
              break;
            default:
          }
        }
      }

      let lstGeo = [];
      for (let i = 0; i < evt.features.length; i++) {
        if (
          ZoomSelect.Settings.Zoom ||
          ZoomSelect.Settings.Hover ||
          ZoomSelect.Settings.Select
        ) {
          lstGeo.push(evt.features[i].geometry);
        }
        if (ZoomSelect.Settings.Query) {
          let feature: _Feature = {
            attributes: { ...evt.features[i].attributes },
          };
          if (ZoomSelect.Settings.ReturnGeometry) {
            feature.geometry = evt.features[i].geometry;
          }
          Result.push(feature);
        }
      }
      let unionGeo;
      if (
        pView &&
        (ZoomSelect.Settings.Zoom ||
          ZoomSelect.Settings.Select ||
          ZoomSelect.Settings.Hover)
      ) {
        unionGeo = geometryEngine.union(lstGeo);

        if (
          lstGeo.length > 0 &&
          evt.features[0].geometry.spatialReference.wkid !==
            pView.spatialReference.wkid
        ) {
          var params = new ProjectParameters({
            geometries: [unionGeo],
            outSpatialReference: new SpatialReference({
              wkid: pView.spatialReference.wkid,
            }),
          });

          project(Constant.geometryServiceUrl,params).then((evt) => {
            ZoomSelect._GetProjectedFeatures(
              pView,
              resolve,
              Result,
              symbol,
              evt,
              pGraphicLayer
            );
          });
        } else {
          ZoomSelect._GetProjectedFeatures(
            pView,
            resolve,
            Result,
            symbol,
            [unionGeo],
            pGraphicLayer
          );
          ZoomSelect._FinalizeZoomSelect(resolve, Result);
        }
      } else {
        ZoomSelect._FinalizeZoomSelect(resolve, Result);
      }
    } else {
      ZoomSelect._FinalizeZoomSelect(resolve, Result);
    }
  };
  // function that returns true if value is email, false otherwise
  public static queryTaskErrorHandler = (e: any) => {
    if (e) {
      alert(e);
    }
  };

  // function that returns true if value is email, false otherwise
  public static _GetProjectedFeatures = (
    pView: __esri.MapView,
    pResolve: any,
    pResult: any,
    symbol: any,
    final: any,
    pGraphicLayer?: GraphicsLayer
  ) => {
    if (ZoomSelect.Settings.Select || ZoomSelect.Settings.Hover) {
      let graphic = null;
      graphic = new Graphic({ geometry: final[0], symbol: symbol });
      if (pGraphicLayer) {
        pGraphicLayer.add(graphic);
      } else {
        pView.graphics.add(graphic);
      }
    }
    if (ZoomSelect.Settings.Zoom) {
      if (final[0].type === "point") {
        final[0] = HelperGeometry.doBuffer(final[0], {
          unit: "meters",
          width: 100,
        });
      }
      let SelectedFeatureExtent = null;
      if (ZoomSelect.ExpandValue) {
        SelectedFeatureExtent = final[0].extent.expand(ZoomSelect.ExpandValue);
      } else {
        SelectedFeatureExtent = final[0].extent;
      }
      pView.goTo(
        { target: SelectedFeatureExtent },
        {
          duration: 1750,
          easing: "linear",
        }
      );
    }
    ZoomSelect._FinalizeZoomSelect(pResolve, pResult);
  };

  public static _FinalizeZoomSelect = (pResolve: any, pResult: any) => {
    pResolve(pResult);
  };
}
