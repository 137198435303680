import createMyTheme from "theme/create-my-theme";
import {
  blackColor,
  primaryColor,
  whiteColor,
  grayColor,
  infoColor,
} from "assets/jss/app";

export default createMyTheme({
  direction: "rtl",

  typography: {
    fontFamily: ["DroidArabicKufiRegular"].join(","),
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: whiteColor,
    },
    common: {
      black: blackColor,
      white: whiteColor,
    },
    info: {
      main: infoColor,
    },
    text: {
      primary: blackColor,
      secondary: grayColor,
    },
  },
});
