import { drawerWidth, primaryColor } from "assets/jss/app";
import { Theme } from "@material-ui/core/styles";

const sidebarStyle = (theme: Theme) => ({
  drawer: {
    // [theme.breakpoints.up("sm")]: {
    //   width: drawerWidth,
    //   flexShrink: 0,
    // },
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  drawerLinks: {
    flex: 1,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedText: {
    fontSize: "0.61rem",
  },
  activeLink: {
    textDecoration: "none",
    color: primaryColor,
  },
});

export default sidebarStyle;
